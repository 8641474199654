import { render, staticRenderFns } from "./hk-link-character.vue?vue&type=template&id=5ecba267&scoped=true"
import script from "./hk-link-character.vue?vue&type=script&lang=js"
export * from "./hk-link-character.vue?vue&type=script&lang=js"
import style0 from "./hk-link-character.vue?vue&type=style&index=0&id=5ecba267&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ecba267",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QList,QItem,QItemSection,QItemLabel,QTooltip});qInstall(component, 'directives', {ClosePopup});
